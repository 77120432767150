const config = {
  "CONTRACT_ADDRESS": "0xEEd41d06AE195CA8f5CaCACE4cd691EE75F0683f",
  "AFFILIATE_CONTRACT_ADDRESS": "0x3603b7a7923E58498Cd6313Ea0127aEFB6416EBA",
  "SCAN_LINK": "https://etherscan.io/address/0xEEd41d06AE195CA8f5CaCACE4cd691EE75F0683f",
  "CONTRACT_ADDRESS": "0xEEd41d06AE195CA8f5CaCACE4cd691EE75F0683f",
  "NETWORK": {
    "NAME": "Mainnet",
    "SYMBOL": "ETH",
    "ID": 1
  },
  "NFT_NAME": "cigawrette packs",
  "SYMBOL": "CIG",
  "MAX_SUPPLY": 9999,
  "WEI_COST": 75000000000000000,
  "DISPLAY_COST": 0.075,
  "MARKETPLACE": "Opensea",
  "MARKETPLACE_LINK": "https://opensea.io/assets/ethereum/0xeed41d06ae195ca8f5cacace4cd691ee75f0683f/",
  "SHOW_BACKGROUND": true
}

export default config

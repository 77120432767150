import React from "react";
import { createRoot } from 'react-dom/client';
import App from "./App";
import Affiliate from "./Affiliate";
import Pool from './Pool';
import Contract from "./Contract";
import ScrollToTop from "./ScrollToTop";
import reportWebVitals from "./reportWebVitals";
import store from "./redux/store";
import { Provider } from "react-redux";
import "./styles/reset.css";
import { BrowserRouter as Router, Route, Routes} from 'react-router-dom';
import { Analytics } from '@vercel/analytics/react';
import { WagmiProvider } from 'wagmi';
import { config } from './wagmi.config';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { RainbowKitProvider, lightTheme } from '@rainbow-me/rainbowkit';
import '@rainbow-me/rainbowkit/styles.css';

let theme = lightTheme()

theme.colors.accentColor = 'linear-gradient(to right bottom, rgba(254, 202, 202, 0.3), rgba(252, 165, 165, 0.3), rgba(254, 240, 138, 0.3))'
theme.colors.accentColorForeground = '#a65017'

const queryClient = new QueryClient();

const root = createRoot(document.getElementById("root"))

root.render(
  <WagmiProvider config={config}>
    <QueryClientProvider client={queryClient}>
      <RainbowKitProvider theme={theme}>
        <Provider store={store}>
          <Router>
            <ScrollToTop/>
            <Routes>
              {
                // <Route path="contract" element={<Contract/>} />
              }
              <Route path="pool" element={<Pool />} />
              <Route path="affiliate" element={<Affiliate />} />
              <Route index element={<App />} />
            </Routes>
          </Router>
          <Analytics/>
        </Provider>
      </RainbowKitProvider>
    </QueryClientProvider>
  </WagmiProvider>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { loadContracts } from "./redux/blockchain/blockchainActions";
import { fetchData, fetchProof, fetchBalanceOf, fetchAffiliateTransfers } from "./redux/data/dataActions";
import { isAddress } from "ethers";
import { Link } from 'react-router-dom'
import { track } from '@vercel/analytics'
import { ConnectButton } from '@rainbow-me/rainbowkit';
import { useAccount, useAccountEffect } from 'wagmi';

const imageLink = 'https://bafybeia3sr25gyzjo6ycy4edkfh4ww3qrj4ymif4xeum5i2bwgt7rf4tni.ipfs.nftstorage.link/ipfs/bafybeia3sr25gyzjo6ycy4edkfh4ww3qrj4ymif4xeum5i2bwgt7rf4tni/images/'

function App() {
  const dispatch = useDispatch();
  const blockchain = useSelector((state) => state.blockchain);
  const data = useSelector((state) => state.data);
  const [feedback, setFeedback] = useState(`Your affiliate link:  `);
  const [affiliateLink, setAffiliateLink] = useState("");
  const [copyLinkButtonText, setCopyLinkButtonText] = useState("copy link 📋");
  const [ensNameToCheck, setEnsNameToCheck] = useState("");
  const [succesful, setSuccessful] = useState(false)

  const { address } = useAccount()

  const getBalanceOf = (address) => {
    dispatch(fetchBalanceOf(address));
  }

  const getAffiliateTransfers = (address) => {
    dispatch(fetchAffiliateTransfers(address))
  }

  const updateAddress = (address) => {
    if(isAddress(address)) {
      setAffiliateLink(window.location.origin+'/pool?af=' + btoa(address))
      getBalanceOf(address)
      getAffiliateTransfers(address)
    }
  }

  useEffect(() => {
    dispatch(loadContracts())
  }, []);

  useEffect(() => {
    if(blockchain.contractsLoaded) {
      updateAddress(address)
    }
  }, [address, blockchain.contractsLoaded])

  useAccountEffect({
    onConnect(data) {
      updateAddress(data.address)
      track('Connect Affiliate')
    }
  })



  return (
    <>
      {
        (
          <div className="app flex items-center text-center w-full p-4">
            <h1 className="m-4 font-extrabold leading-none tracking-tight text-black-900 text-2xl md:text-3xl lg:text-4xl justify-center text-center">
              Welcome to the<br/>Cigawrette Packs Affiliate Program
            </h1>
            <p className="max-w-md mb-4 text-lg font-normal text-gray-500">
              Create and share an affiliate link to earn a <br/>15% commission on purchased cigawrettes.
            </p>
            <p className="max-w-md mb-4 text-lg font-normal text-gray-500">
              20% if you own at least 1 cigawrette,<br/>25% if you own 15 or more.
            </p>
            <p className="max-w-md mb-4 text-lg font-normal text-gray-500">
              No redemption necessary,<br/>funds are sent to your wallet at purchase!
            </p>

            <div className="flex flex-col p-4 items-center text-center bg-gradient-to-r from-blue-500/10 to-purple-500/10 m-2 rounded-md">
              {!address ? (
                <div className="flex flex-col items-center p-4 m-8 text-lg font-normal text-black">
                  Become an affiliate
                  <div className="flex [&>div>button]:items-center pt-6">
                    <ConnectButton/>
                  </div>
                  {blockchain.errorMsg !== "" ? (
                    <div>
                        {blockchain.errorMsg}
                    </div>
                  ) : null}
                </div>
              ) : (
                <>
                  {address ? (
                    <>
                      <div className="flex p-2 text-lg font-bold">
                        Your Affiliate Link:
                      </div>
                      <div className="w-1/2 flex font-mono text-xs md:text-lg p-4 justify-center text-wrap break-all sm:break-keep sm:break-words">
                        {affiliateLink}
                      </div>
                      <button
                        className="px-8 py-4 bg-gradient-to-r from-blue-500 to-purple-500 text-white font-bold rounded-full transition-transform transform-gpu hover:scale-105 hover:shadow-lg"
                        onClick={() => {
                          navigator.clipboard.writeText(affiliateLink);
                          setCopyLinkButtonText("link copied to clipboard!");
                          setTimeout(() => {
                            setCopyLinkButtonText("copy link 📋");
                          }, 2000);
                        }}
                      >
                        {copyLinkButtonText}
                      </button>
                    </>
                  ) : (
                    <div>invalid address - use a checksummed address or a valid ENS name</div>
                  )
                }
                </>
              )
            }
            </div>
            {isAddress(address) && (<div className="p-4 text-lg flex flex-col justify-between bg-gradient-to-r from-blue-500/10 to-purple-500/10 m-2 rounded-md">
              <div className="font-bold pb-2">Your Affiliate Dashboard</div>
              <table className="table-auto text-left">
                <tbody>
                  <tr>
                    <td className="mr-6">Cigawrette Balance</td>
                    <td className="font-bold">{data.balance}</td>
                  </tr>
                  <tr>
                    <td className="pr-6">Affiliate Percentage</td>
                    <td className="font-bold">{data.balance > 0 ?
                         data.balance > 15 ?
                          25 : 20 : 15
                        }%
                    </td>
                  </tr>
                  <tr className="">
                    <td className="mr-6">Affiliate Earnings</td>
                    <td className="font-bold">
                    {
                      data.transfers.length > 0 ?
                      data.transfers.map(t => t.value).reduce((t,acc) => {
                        return acc + t
                      },0) : 0
                    } Ξ
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>)}
            <p className="max-w-screen-sm mb-4 text-lg font-normal text-blue-500">
              <Link to={'https://etherscan.io/address/0x3603b7a7923E58498Cd6313Ea0127aEFB6416EBA#code'}>Read the affiliate contract ....</Link>
            </p>
            <p className="max-w-screen-sm mb-4 text-lg font-normal text-blue-500">
              <Link to={'/'}>Learn more about cigawrette packs ....</Link>
            </p>
            <div className="fixed top-0 right-0">
              <Link
                className="sm:flex justify-center bg-white items-center drop-shadow-[2px_2px_4px_rgba(0,0,0,0.15)] background-animate bg-gradient-to-br from-red-200/30 via-red-300/30 to-yellow-200/30 hover:from-red-200/40 hover:via-red-300/40 hover:to-yellow-200/40 rounded-lg m-2 py-2 px-6"
                to={'/pool'}
                onClick={() => track('Shop the Bodega', { page: 'Affiliate'})}
              >
                Shop the Bodega
              </Link>
            </div>
        </div>
      )
    }
    </>
  )
}

export default App

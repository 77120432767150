import { useEffect, useState, useRef } from 'react'
import { useLocation } from 'react-router-dom'
import { isAddress } from 'ethers'
import { ShoppingCart, Plus, Minus, Square, CheckSquare, ExternalLink, X } from 'lucide-react'
import Modal from 'react-modal'
import { Link } from "react-router-dom"
import { Decimal } from 'decimal.js'
import { useDispatch, useSelector } from "react-redux"
import { transfer, loadContracts } from "./redux/blockchain/blockchainActions"
import Search from "./Search"
import { useConnectModal } from '@rainbow-me/rainbowkit';
import { useAccount, useAccountEffect, useSimulateContract, useWriteContract } from 'wagmi';
import { track } from '@vercel/analytics'
import config from './config/config'
import affiliateAbi from './config/affiliate_abi'

const baseURL = 'https://ipfs.io/ipfs/bafybeigvhgkcqqamlukxcmjodalpk2kuy5qzqtx6m4i6pvb7o3ammss3y4/'
const blurURL = 'https://blur.io/asset/0xeed41d06ae195ca8f5cacace4cd691ee75f0683f/'

Modal.setAppElement('#root')

// turn flashSale on/off -- true is on, false is off
const flashSale = false
// edit flashSale prices and tiers here
const flashSalePrices = [0,.012,.015,.013,.011,.009]
const flashSaleTiers = [2,5,25,50,100]

const prices = flashSale ? flashSalePrices : [.029,.025,.023,.021,.019]
const tiers  = flashSale ? flashSaleTiers : [5,25,50,100]

const getPriceOriginal = (quantity) => {
  let price = 0

  if(quantity < tiers[0]) {
    price = prices[0]
  } else if (quantity < tiers[1]) {
    price = prices[1]
  } else if (quantity < tiers[2]) {
    price = prices[2]
  } else if (quantity < tiers[3]) {
    price = prices[3]
  } else {
    price = prices[4]
  }

  const unit = new Decimal(quantity)

  return parseFloat(unit.mul(price))
}

const getPriceFlashSale = (quantity) => {
  let price = 0

  if(quantity < tiers[0]) {
    price = prices[0]
  } else if (quantity < tiers[1]) {
    price = prices[1]
  } else if (quantity < tiers[2]) {
    price = prices[2]
  } else if (quantity < tiers[3]) {
    price = prices[3]
  } else if (quantity < tiers[4]) {
    price = prices[4]
  } else {
    price = prices[5]
  }

  const unit = new Decimal(quantity)

  return parseFloat(unit.mul(price))
}

const getPrice = flashSale ? getPriceFlashSale : getPriceOriginal

const Cart = ({cart, setCart, modalIsOpen, setModalIsOpen, checkout, feedback}) => {

  useEffect(() => {
    const body = document.querySelector('body');
    body.style.overflow = modalIsOpen ? 'hidden' : 'auto';
  }, [modalIsOpen])

  const EmptyCart = () => (<div className="grid h-full place-items-center text-lg font-bold text-blue-900"><p>Fill up your Cart!</p></div>)

  const FullCart = () => (
    <div className="flex flex-col bg-stone-100 sm:flex-row h-full items-center overflow-hidden">
          <div className={`h-full place-items-center justify-center gap-4 p-4 rounded-lg bg-stone-200 overflow-y-scroll grid ${(cart.length <= 3) ? ((cart.length == 1) ? 'grid-cols-1' : `grid-cols-2`) : "grid-cols-2 sm:grid-cols-3 md:grid-cols-4"}`}>
            {cart.map((tokenId,i) => (
              <div
                key={'cart'+tokenId}
                className="relative h-min max-w-full flex flex-col justify-center items-center"
                >
                <img
                  className="w-full max-h-[50vh] rounded-lg"
                  crossOrigin="true"
                  src={baseURL + tokenId + '.jpg'}
                />
                <Minus
                  className="absolute top-[-.75em] right-[-.75em] scale-125 text-red-900 bg-neutral-50 hover:bg-neutral-100 rounded-full"
                  onClick={() => setCart(cart.filter(i => i !== tokenId))}
                />
              </div>
          ))}
        </div>
        <div className="flex flex-1 items-center justify-center flex-col w-full h-fit m-4">
          <div className="text-lg">{`${cart.length} ${cart.length > 1 ? 'cigs' : 'cig'},`}</div>
          <div className="text-lg">{`that'll be`}</div>
          <div className="text-lg">{`${getPrice(cart.length)} Ξ`}</div>
          <button
            className="flex h-min mb-4 bg-white drop-shadow-[2px_2px_4px_rgba(0,0,0,0.15)] background-animate bg-gradient-to-br from-red-200/30 via-red-300/30 to-yellow-200/30 hover:from-red-200/40 hover:via-red-300/40 hover:to-yellow-200/40 rounded-lg my-2 py-4 px-6"
            onClick={() => {
              checkout(cart)
              track('Checkout')
            }}
          >
            Checkout
          </button>
        </div>
      </div>)

  return (
    <Modal
      isOpen={modalIsOpen}
      onRequestClose={() => setModalIsOpen(false)}
      style={{
        overlay: {
          background: 'rgba(0, 0, 0, 0.5)',
          zIndex: 20
        },
        content: {
          padding: 0,
          background: 'white',
          border: 'none',
          borderRadius: '.5rem'

        }}}
    >
    {
      (cart.length > 0) ? <FullCart /> : <EmptyCart />
    }
    </Modal>
  )
}

const Pool = () => {
  const dispatch = useDispatch();
  const blockchain = useSelector((state) => state.blockchain);
  const [affiliate, setAffiliate] = useState(null)
  const [pool, setPool] = useState([])
  const [poolMeta, setPoolMeta] = useState({})
  const [poolFetched, setPoolFetched] = useState(false)
  const [cart, _setCart] = useState(JSON.parse(localStorage.getItem("cart")) || [])
  const [modalIsOpen, setModalIsOpen] = useState(false)
  const location = useLocation()
  const scrollRef = useRef(null)
  const [scrollY, setScrollY] = useState(0)
  const [tokensToClaim, setTokensToClaim] = useState([])
  const [feedback, setFeedback] = useState("")
  const [brands, setBrands] = useState(new Set([]))
  const [brand, setBrand] = useState("")
  const [footerClosed, setFooterClosed] = useState(false)

  const { address } = useAccount()
  const { openConnectModal } = useConnectModal()
  const { data: hash, writeContract } = useWriteContract()

  useEffect(() => {
    dispatch(loadContracts())
  }, []);

  const setCart = (b) => {
    localStorage.setItem("cart", JSON.stringify(b))
    _setCart(b)
  }

  const claimTokens = async (tokens, address) => {
    // check to make sure tokensToClaim are still available
    const response = await fetch('api/get_pool.js')
    const _pool = await response.json()
    setPool(_pool)
    const alreadySold = tokens.filter(tokenId => _pool.indexOf(tokenId) === -1)
    // return early with error, if tokens are already sold
    if (alreadySold.length > 0) {
      setFeedback(
        <>
          <div className="flex flex-col items-center pb-4 text-lg">
            <p>{`Sorry, ${(alreadySold.length > 1) ? `these packs` : ` this pack`} just sold:`}</p>
          </div>
          <Carousel tokenIds={alreadySold} externalLink/>
        </>
      )

      setTimeout(() => {
        setTokensToClaim([])
        setFeedback("")
      }, 5000)
      alreadySold.map((tokenId) => setCart(cart.filter(i => i !== tokenId)))
      return
    }

    // prevent accidentally becoming your own affiliate
    const affiliateAddress = (affiliate && affiliate !== address.toLowerCase() && affiliate !== address) ? affiliate : '0x0000000000000000000000000000000000000000'

    const value = new Decimal(getPrice(tokens.length))

    try {
      writeContract({
        address: config.AFFILIATE_CONTRACT_ADDRESS,
        abi: affiliateAbi,
        functionName: 'transfer',
        args: [tokens.map(BigInt), affiliateAddress],
        value: String(parseFloat(value.mul(1000000000000000000)))
      },
      {
        onSuccess: () => {
          let purchased = tokens
          setFeedback(
            <>
              <div className="flex flex-col items-center pb-4 text-lg">
                <p>{`WOW, the cigawrette pack${tokens.length > 1 ? 's are' : ' is'} yours!`}</p>
                <p className="flex items-center">Click <ExternalLink className="scale-[70%]"/> to view on Blur.</p>
              </div>
              <Carousel tokenIds={purchased} externalLink/>
            </>
          )
          tokens.map((tokenId) => {
            setCart(cart.filter(i => i !== tokenId))
            setPool(pool.filter(i => i !== tokenId))
          })
        },
        onError: (err) => {
          console.log(err)
          // setFeedback(err.message, err.stack)
          if (err.name == 'TransactionExecutionError') {
            setTokensToClaim([])
          } else if (err.shortMessage == "The total cost (gas * gas fee + value) of executing this transaction exceeds the balance of the account.") {
            setFeedback("It seems you don't have enough ETH, please check your wallet and try again.")
          } else {
            // setFeedback(<>
            //   <p>{err.name}</p>
            //   <p>{err.shortMessage}</p>
            //   <p>{err.message}</p>
            //   <p>{err.stack}</p>
            // </>)
            setFeedback("Sorry, something went wrong please try again.")
          }
        }
      })
    } catch (e) {
      console.log(e)
    }
  }

  useAccountEffect({
    onConnect(data) {
      if((tokensToClaim.length > 0) && isAddress(data.address)) {
        claimTokens(tokensToClaim, data.address)
      }
    }
  })

  useEffect(() => {
    // get cigs in pool
    fetch('api/get_pool.js')
      .then((response) => response.json())
      .then((_pool) => {
        setPoolFetched(true)
        setPool(_pool)
        _pool.map(tokenId => {
          fetch('https://ipfs.io/ipfs/bafybeidgwnebxxrcjj3glcxtncvkeuokynlvb3oimrp4nwmv7sds34lela/'+tokenId+'.json')
            .then(response => response.json())
            .then(meta => {
              setPoolMeta(Object.assign(poolMeta, {[tokenId]: meta}))
              setBrands(brands.add(meta.attributes[8].value))
            })
        })

        setCart(cart.filter(item => pool.includes(item)))
      })

    // if affiliate is already saved to localStorage, use that and return
    const localStorageAffiliate = localStorage.getItem("affiliate")
    if (localStorageAffiliate !== null) {
      setAffiliate(localStorageAffiliate)
      return
    }

    // else, check for new affiliate and save to localStorage
    const queryParams = new URLSearchParams(location.search)
    const af = queryParams.get('af')
    if (af !== undefined) {
      const address = atob(af)
      if (isAddress(address)) {
        setAffiliate(address)
        localStorage.setItem("affiliate", address)
      }
    }
  },[])

  const Footer = ({scrollRef, footerClosed, setFooterClosed}) => {
    const scrollTopValue =
      scrollRef.current &&
      scrollRef.current.children &&
      scrollRef.current.children[0] &&
      scrollRef.current.children[0].scrollTop;
    const [scrollY, setScrollY] = useState(scrollTopValue || 0)

    useEffect(() => {
      const handleScroll = () => {
        if (scrollRef.current) {
          setScrollY(scrollRef.current.children[0].scrollTop)
        }
      }
     if (scrollRef.current) {
       scrollRef.current.children[0].addEventListener('scroll', handleScroll)

       return () => {
         if (scrollRef.current) {
           scrollRef.current.children[0].removeEventListener('scroll', handleScroll)
         }
       }
     }
    },[])

    return (
      <div className={`fixed bottom-0 p-2 m-0 pointer-events-none flex justify-center h-min w-full bg-white bg-gradient-to-b from-blue-100/50 via-blue-100/50 to-blue-300/50 transition-opacity duration-500 ${(scrollY > 30 || footerClosed) ? 'opacity-0' : 'opacity-100'}`}>
        <p className="w-7/8 sm:w-3/4 align-center leading-5 sm:leading-6 text-center text-md sm:text-md">
          The Cyber Bodega is a pool of cigawrettes set aside for our <Link to={'/affiliate'} className="text-blue-700 hover:text-blue-900 pointer-events-auto">affiliate program</Link>.
          <br/>Packs are pre-minted into the pool and revealed. They can be batch purchased à la carte and there is a slight discount from mint price.
          <br/>If you’d like to mint a fresh pack you can do so in the <Link to={'/'} className="text-blue-700 hover:text-blue-900 pointer-events-auto">shop</Link>
        </p>
        <div>
          <X
            className="absolute bottom-0 right-0 hover:bg-slate-200 pointer-events-auto"
            onClick={() => setFooterClosed(true)}
          />

        </div>
      </div>)
  }

  const BuyEmAll = () => (<button
    className="w-fit flex text-center justify-center bg-white drop-shadow-[2px_2px_4px_rgba(0,0,0,0.15)] background-animate bg-gradient-to-br from-red-200/30 via-red-300/30 to-yellow-200/30 hover:from-red-200/40 hover:via-red-300/40 hover:to-yellow-200/40 rounded-lg py-2 p-4"
    onClick={() => {
      setTokensToClaim(pool)
      if(!isAddress(address)) {
        openConnectModal()
      } else {
        claimTokens(pool, address)
      }
      track('Buy Em All!')
    }}
    disabled={pool.length < 1}
  >
    Buy Em All!
  </button>)

  const FlashSale = () => (<button
    className="w-fit flex text-center justify-center bg-white drop-shadow-[2px_2px_4px_rgba(0,0,0,0.15)] background-animate animate-pulse bg-gradient-to-br from-yellow-200/30 via-yellow-500/30 to-yellow-200/30 hover:from-yellow-200/40 hover:via-red-300/40 hover:to-yellow-200/40 rounded-lg py-2 p-4"
    onClick={() => {
    }}
  >
    Flash Sale!
  </button>)

  const checkout = (cart) => {
    setModalIsOpen(false)
    setTokensToClaim(cart)
    if(!isAddress(address)) {
      openConnectModal()
    } else {
      claimTokens(cart, address)
    }
  }

  const Spinner = () => (
    <div className="flex" role="status">
      <svg aria-hidden="true" className="w-6 h-6 text-blue-200 animate-spin fill-blue-500 drop-shadow-[2px_2px_4px_rgba(0,0,0,0.15)]" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor"/>
          <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill"/>
      </svg>
      <span className="sr-only">Loading...</span>
    </div>
  )

  const Checkout = () => {
    const Progress = () => (
      <>
        <div className="flex flex-row p-4 justify-center">
          <div className="italic">{`Buying ${tokensToClaim.length} ${tokensToClaim.length > 1 ? 'cigs' : 'cig'}`}</div>
        </div>
        <div className="flex flex-row p-4">
          <div className="px-2">{!isAddress(address) ? <Spinner/> : <CheckSquare />}</div> Connect Wallet
        </div>
        <div className="flex flex-row p-4">
          <div className="px-2">{!isAddress(address) ? <Square className="stroke-blue-500" /> : <Spinner />}</div> {`Buy Cigawrette Pack${(tokensToClaim.length > 1) ? 's' : ''}`}
        </div>
      </>)

    return (<div className="absolute w-[100vw] h-[100dvh] bg-slate-100/90 flex flex-col justify-center items-center bg-blur-md z-50">
      <div className="flex flex-col w-fit max-w-[100%] bg-white bg-gradient-to-b from-blue-100/50 via-blue-100/50 to-blue-300/50 rounded-lg p-6">
        <h1 className="flex justify-center text-lg pt-2 pb-6 font-bold">Check Out</h1>
        {
          (feedback !== "") ?
            <>
              <div className="p-4">{feedback}</div>
              <button
                className="justify-center self-center bg-white background-animate drop-shadow-[2px_2px_4px_rgba(0,0,0,0.15)] bg-gradient-to-br from-red-200/30 via-red-300/30 to-yellow-200/30 hover:from-red-200/40 hover:via-red-300/40 hover:to-yellow-200/40 rounded-lg mt-2 py-2 px-4"
                onClick={() => {
                  setTokensToClaim([])
                  setFeedback("")
                }}
              >
                Keep Shopping
              </button>
            </>
          : <Progress/>
        }
      </div>
    </div>
    )
  }

  const EmptyPool = () => (<div className="w-[100vw] max-w-full h-[70vh] bg-stone-100 rounded-lg grid place-items-center text-lg text-blue-500 font-bold text-center"><p>The pool is empty right now, head to the <Link to={'/'} className="text-blue-700 hover:text-blue-900 pointer-events-auto">shop</Link> to mint a fresh pack or check back here soon!</p></div>)


  const InfiniteMarquee = ({ flashSale }) => {
    const _prices = prices.map((p,i) => {
      const _tiers = [1].concat(tiers)
      return flashSale ? i == 0 ?
        `1 pack for FREE` :
        `${_tiers[i]}+ for ${p} Ξ / pack`
        : `${_tiers[i]}+ for ${p} Ξ / pack`
    })

    return (
      <div className="w-[100vw]">
      <div className="w-[250vw] sm:w-[100vw] flex overflow-hidden gap-2 text-xs sm:text-sm font-mono [mask-image:_linear-gradient(to_right,transparent_0,_black_350px,_black_calc(100%-350px),transparent_100%)]">
        <ul className="flex min-w-full items-center justify-around gap-2 animate-infinite-marquee">
          {_prices.map((price, index) => {
            const bgGradient = flashSale ?
              index == 0 ?
                'from-green-200/30 via-green-500/30 to-green-200/30 animate-pulse' : 'from-yellow-200/30 via-yellow-500/30 to-yellow-200/30 animate-pulse'
              : 'from-blue-100/50 via-blue-100/50 to-blue-300/50'
            const bgClass = `flex items-center h-full bg-white bg-gradient-to-b ${bgGradient} rounded-lg p-3`
            return (
              <li
                className={bgClass}
                key={index}
              >
                <strong>{price}</strong>
              </li>
            )
          })}
        </ul>
        <ul className="flex min-w-full items-center justify-around gap-2 animate-infinite-marquee" aria-hidden="true">
          {_prices.map((price, index) => {
            const bgGradient = flashSale ?
              index == 0 ?
                'from-green-200/30 via-green-500/30 to-green-200/30 animate-pulse' : 'from-yellow-200/30 via-yellow-500/30 to-yellow-200/30 animate-pulse'
              : 'from-blue-100/50 via-blue-100/50 to-blue-300/50'
            const bgClass = `flex items-center h-full bg-white bg-gradient-to-b ${bgGradient} rounded-lg p-3`
            return (
              <li
                className={bgClass}
                key={index}
              >
                <strong>{price}</strong>
              </li>
            )
          })}
        </ul>
    </div>
  </div>);
  };

  const Carousel = ({tokenIds, externalLink}) => {
    return (
      <div className={`flex ${tokenIds.length < 4 ? 'justify-center' : 'justify-start'} overflow-x-auto scrolling-container gap-5 w-full z-[1000]`}>
        {
          tokenIds.map((tokenId, i) => (<div key={i} className="relative flex-none w-1/4">
            <img
              className={`w-full h-auto justify-items-center drop-shadow-[2px_2px_4px_rgba(0,0,0,0.15)] rounded-lg`}
              crossOrigin="true"
              src={baseURL + tokenId + '.jpg'}
            />
            {
              (externalLink && (
                <div className="absolute flex flex-row items-center gap-2 bottom-[.125em] right-[.125em] scale-[70%]">
                  <Link to={`https://twitter.com/share?text=just got new some @cigawrettepacks%0a&url=${blurURL+tokenId}&hashtags=cigawrettes`} target="_blank" rel="noopener noreferrer">
                    <div className="w-[30px] bg-black/60 hover:bg-black p-2 rounded-full">
                      <img src="./x-logo-white.png" alt="tweet" className="w-full"/>
                    </div>
                  </Link>
                  <Link to={blurURL+tokenId} target="_blank" rel="noopener noreferrer" className="opacity-60 hover:opacity-100">
                    <ExternalLink/>
                  </Link>
                </div>))
            }
          </div>
        ))}
      </div>)
  }

// testing -  remove in prod
  useEffect(() => {
    // setTokensToClaim(pool)
    // setFeedback(
    //   <>
    //     <div className="flex flex-col items-center pb-4 text-lg">
    //       <p>{`WOW, the cigawrette pack${tokensToClaim.length > 1 ? 's are' : ' is'} yours!`}</p>
    //       <p className="flex items-center">Click <ExternalLink className="scale-[70%]"/> to view on Blur.</p>
    //     </div>
    //     <Carousel tokenIds={pool} externalLink/>
    //   </>)

    // const alreadySold = pool.slice(-1)
    //   setFeedback(
    //     <>
    //       <div className="flex flex-col items-center pb-4 text-lg">
    //         <p>{`Sorry, ${(alreadySold.length > 1) ? `these packs` : ` this pack`} just sold:`}</p>
    //       </div>
    //       <Carousel tokenIds={alreadySold} externalLink/>
    //     </>
    //   )

  },[poolFetched])

  return (
    <div className="flex flex-col h-full">
      {(tokensToClaim.length > 0) && <Checkout/>}

      <Cart cart={cart} setCart={setCart} modalIsOpen={modalIsOpen} setModalIsOpen={setModalIsOpen} checkout={checkout} feedback={feedback} />

      <div className="fixed top-0 w-full z-20 flex items-center justify-end px-6 py-2">
        <Link
          className="hidden sm:flex justify-center bg-white items-center drop-shadow-[2px_2px_4px_rgba(0,0,0,0.15)] background-animate bg-gradient-to-br from-red-200/30 via-red-300/30 to-yellow-200/30 hover:from-red-200/40 hover:via-red-300/40 hover:to-yellow-200/40 rounded-lg m-2 py-2 px-6"
          to={'/affiliate'}
          onClick={() => track('Become an Affiliate', { page: 'Pool' })}
        >
          Become an Affiliate
        </Link>
        <div
          className={`relative flex justify-center rounded-full h-fit p-[10px] drop-shadow-[2px_2px_4px_rgba(0,0,0,0.15)] bg-gradient-to-br ${(cart.length > 0) ? 'from-green-100 to-green-500' : 'from-white to-white'} hover:from-red-200/30 hover:via-red-300/30 hover:to-yellow-200/30`}
          onClick={() => setModalIsOpen(true)}
        >
          <ShoppingCart className=""/>
          {(cart.length > 0) && (<div className="absolute flex justify-center top-[-.5em] right-[-.75em] scale-[60%] text-black text-md p-2 rounded-full bg-white font-mono w-8 h-8 align-center leading-[normal]">{cart.length}</div>)}
        </div>
      </div>
      {
        (flashSale && <div className="fixed top-0 w-fit z-20 flex items-center justify-start px-6 py-4">
          <FlashSale/>
        </div>)
      }

      <div className="flex flex-col h-dvh items-center justify-start sm:justify-between text-center">
        <div className="flex flex-col items-center w-full p-2">
          <h1 className="text-sm mt-2 leading-none tracking-tight text-black-900 md:text-md lg:text-lg">
            The Cigawrette Packs
          </h1>
          <h1 className="text-2xl leading-none tracking-tight text-black-900 md:text-3xl lg:text-4xl">
            Cyber Bodega
          </h1>
        </div>
        <div className="relative overflow-hidden my-3 sm:m-0">
          <InfiniteMarquee flashSale={flashSale}/>
          <div className="absolute flex h-full items-center margin-auto right-0 px-6 top-0">
            <BuyEmAll/>
          </div>
        </div>

        <div ref={scrollRef} className="flex justify-center h-[80%]">
        {
          (!poolFetched) ? (<div className="w-[100vw] flex items-center justify-center"><Spinner/></div>)  :
           (pool.length == 0) ?
              <EmptyPool/> :
              (<div className="w-4/5 sm:w-full bg-stone-100 rounded-lg overflow-y-scroll grid grid-cols-1 md:grid-cols-4 lg:grid-cols-5 gap-6 place-items-center p-6">

              <Search brandSet={brands} brand={brand} setBrand={setBrand} />

              {pool.filter(tokenId => {
                if (Object.keys(poolMeta).length < 2 || brand === "") return true
                console.log(brand, poolMeta[tokenId].attributes[8])
                return brand == poolMeta[tokenId].attributes[8].value
              }).map((tokenId,i) => (
                <div
                  key={'pool'+tokenId}
                  className="relative h-min max-w-full flex flex-col w-7/8 sm:w-full"
                  >
                  <img
                    className={`w-full justify-items-center drop-shadow-[2px_2px_4px_rgba(0,0,0,0.15)] rounded-lg ${(cart.indexOf(tokenId) == -1) ? '' : 'border-solid border-2 border-purple-500'}`}
                    crossOrigin="true"
                    src={baseURL + tokenId + '.jpg'}
                  />
                  <div className="flex flex-col justify-between w-full text-sm">
                    <button
                      className="justify-center bg-white background-animate drop-shadow-[2px_2px_4px_rgba(0,0,0,0.15)] bg-gradient-to-br from-red-200/30 via-red-300/30 to-yellow-200/30 hover:from-red-200/40 hover:via-red-300/40 hover:to-yellow-200/40 rounded-lg mt-2 py-2 px-4"
                      onClick={() => (cart.indexOf(tokenId) == -1) ? setCart([...cart, tokenId]) : setCart(cart.filter(i => i !== tokenId))}
                    >
                      {(cart.indexOf(tokenId) == -1) ? 'Add to Cart' : 'Remove from Cart' }
                    </button>
                    <button
                      className="justify-center bg-white background-animate drop-shadow-[2px_2px_4px_rgba(0,0,0,0.15)] bg-gradient-to-br from-red-200/30 via-red-300/30 to-yellow-200/30 hover:from-red-200/40 hover:via-red-300/40 hover:to-yellow-200/40 rounded-lg mt-2 py-2 px-4"
                      onClick={async () => {
                        setTokensToClaim([tokenId])
                        if(!isAddress(address)) {
                          openConnectModal()
                        } else {
                          claimTokens([tokenId], address)
                        }
                        track('Buy Now')
                      }}
                    >
                      Buy Now
                    </button>
                  </div>
                </div>
              ))}
              </div>)
            }
        </div>
      </div>

      <Footer scrollRef={scrollRef} footerClosed={footerClosed} setFooterClosed={setFooterClosed}/>

    </div>
  )
}

export default Pool

import React from "react";
import { Link } from "react-router-dom"
import { track } from '@vercel/analytics'

const Content = () => {
  return (
    <div className="content">
      <span className="center">
        If mint button returns an error like high gas fees, mint from here instead: <a className="underline text-blue-700 hover:text-blue-900 visited:text-purple-900" target="_blank" rel="noopener noreferrer" href="https://mint.fun/0xeed41d06ae195ca8f5cacace4cd691ee75f0683f">mint.fun</a>. There is also a maximum of 19 fresh mints per wallet. If you attempt to go over 19 you will receive a high gas error. Use a different wallet to mint if this occurs or bulk purchase in the <a className="underline text-blue-700 hover:text-blue-900 visited:text-purple-900" target="_blank" rel="noopener noreferrer" href="https://www.cigawrettepacks.shop/pool"> Cyber Bodega pool</a>. Contact us on <a target="_blank" rel="noopener noreferrer" href="https://twitter.com/cigawrettepacks"> Twitter</a> with any other issues.
      </span>
      <span className="center">
        Cigawrette Packs is an experimental literature project consisting of 9999 cigawrette packs (most) with blank warning labels that can be written on and shared.
      </span>
      <span>
        <a className="underline text-blue-700 hover:text-blue-900 visited:text-purple-900" target="_blank" rel="noopener noreferrer" href="https://cigbot.life">
          Customize Text on Warning Label
        </a>
      </span>
      <img src="line_break.png" alt="line_break" width="240px" />
      <span id="art" className="section-heading">
        The Art
      </span>
      <span className="center">
        93 brands, 1000 brand varieties, art by pappachaga (fly high).
      </span>
      <div className="triple">
        {
          [1,2,3].map(i => <img key={i} className="triple-img" alt={`cig-${i}`} src={`/${i}.jpg`}/>)
        }
      </div>
      <span className="center">
        Approximately ten percent of brands are packaged in a subtle, clear plastic wrap with tear string, and have warning labels full of text. However, most brand warning labels are left blank, so you can create your own with <a target="_blank" rel="noopener noreferrer" href="https://cigawrettebot.com">cigbot</a>. If you have nothing to say, your packs can meditate in silent anticipation. One exclusive brand line, ‘President’s Club’, has no warning label or packaging at all.
      </span>
      <span className="center">
        Rarity is both quantitative and qualitative. Visuals often vary across singular sub-brands. Brand styles range from obvious riffs to abstract, absurd and are sometimes even derivative of each other. Boxes are designed in a semi-vector format as a take on the corporatized fiverr NFT style.
      </span>
      <div className="triple">
        {
          [4,5,6].map(i => <img key={i} className="triple-img" alt={`cig-${i}`} src={`/${i}.jpg`}/>)
        }
      </div>
      <img src="line_break.png" alt="line_break" width="240px" />
      <span id="statement" className="section-heading">
        Statement from the CEO
      </span>
      <span className="just">
        Cigawrette packs explore the derivative nature of competitive attention marketplaces and are specifically designed to become derivative in and of themselves. Rather than releasing a ‘complete’ project with all warning labels filled out in a top down, here is the art manner, the packs are released in an MVP x NikeID style fashion. This functions primarily as medium for interactive literature or memes depending on usage, and secondarily, co-opts corporate aesthetics   as a toolset.
      </span>
      <span>-</span>
      <span className="just">
        As NFTs are not yet driven by an institutionalized critic class or art gallery hierarchy funnel, collector-usage and network attention drives relevance. So far we have seen this play out with profile pictures and various forms of future or often high friction utility. Instead, cigawrette packs ask not that you replace your PFP identity or do something obscure and high friction like play a video game, but instead just write in your warning label with <a target="_blank" rel="noopener noreferrer" href="https://cigawrettebot.com">cigawrettebot</a>.
      </span>
      <span></span>
      <img src="line_break.png" alt="line_break" width="240px" />
      <span id="agenda" className="section-heading">Corporate agenda</span>
      <span className="left">To: Whom it may concern</span>
      <span className="left">RE: Cigawrette Packs</span>
      <span className="left">Cigawrette Packs is an initial building block to bring about cyberbotanical paradise on earth.</span>
      <span className="just">As a general brand aesthetic we will leverage corporatism as a medium across brand lines to expand on the notion of NFTs in art and culture, in particular as a method of contractual and financial innovation, not necessarily just JPGs. This may pull from corporate scandal, lifestyle marketing and the grey market cigarette economics that have existed over the past century, hidden behind the scenes.</span>
      <span><a href="#mint" className="underline text-blue-700 hover:text-blue-900 visited:text-purple-900">Mint</a></span>
      <span><Link to={'/affiliate'} className="underline text-blue-700 hover:text-blue-900 visited:text-purple-900">Affiliate</Link></span>
      <span className="center" className="nav">
        <a className="underline text-blue-700 hover:text-blue-900 visited:text-purple-900" target="_blank" rel="noopener noreferrer" href="https://etherscan.io/address/0xeed41d06ae195ca8f5cacace4cd691ee75f0683f#code">
          Contract
        </a>
      </span>
      <span>
        <a  className="underline text-blue-700 hover:text-blue-900 visited:text-purple-900" target="_blank" rel="noopener noreferrer" href="https://twitter.com/cigawrettepacks">
          Twitter
        </a>
      </span>
      <div className="fixed top-0 right-0">
        <Link
          className="sm:flex justify-center bg-white items-center drop-shadow-[2px_2px_4px_rgba(0,0,0,0.15)] background-animate bg-gradient-to-br from-red-200/30 via-red-300/30 to-yellow-200/30 hover:from-red-200/40 hover:via-red-300/40 hover:to-yellow-200/40 rounded-lg m-2 py-2 px-6"
          to={'/pool'}
          onClick={() => track('Shop the Bodega', { page: 'Home' })}
        >
          Shop the Bodega
        </Link>
      </div>
    </div>
  )
}

export default Content

import {Contract as Web3EthContract} from "web3-eth-contract";
import { fetchData } from "../data/dataActions";
import config from '../../config/config'
import abi from '../../config/abi'
import affiliateAbi from '../../config/affiliate_abi'

const connectRequest = () => {
  return {
    type: "CONNECTION_REQUEST",
  };
};

const connectSuccess = (payload) => {
  return {
    type: "CONNECTION_SUCCESS",
    payload: payload,
  };
};

const connectFailed = (payload) => {
  return {
    type: "CONNECTION_FAILED",
    payload: payload,
  };
};

const updateAccountRequest = (payload) => {
  return {
    type: "UPDATE_ACCOUNT",
    payload: payload,
  };
};

export const connect = () => {
  return async (dispatch) => {
    dispatch(connectRequest());

    const { ethereum } = window;
    if (ethereum) {
      try {
        const accounts = await ethereum.request({
          method: "eth_requestAccounts",
        });
        const networkId = await ethereum.request({
          method: "net_version",
        });
        if (networkId == config.NETWORK.ID) {
          const SmartContractObj = new Web3EthContract(
            abi,
            config.CONTRACT_ADDRESS,
            { provider: ethereum }
          );
          const AffiliateContractObj = new Web3EthContract(
            affiliateAbi,
            config.AFFILIATE_CONTRACT_ADDRESS,
            { provider: ethereum }
          );
          dispatch(
            connectSuccess({
              account: accounts[0],
              smartContract: SmartContractObj,
              affiliateContract: AffiliateContractObj,
              web3: null,
            })
          );
          // Add listeners start
          ethereum.on("accountsChanged", (accounts) => {
            dispatch(updateAccount(accounts[0]));
          });
          ethereum.on("chainChanged", () => {
            window.location.reload();
          });
          // Add listeners end
        } else {
          dispatch(connectFailed(`Change network to ${config.NETWORK.NAME}.`));
        }
      } catch (err) {
        dispatch(connectFailed("Something went wrong."));
      }
    } else {
      dispatch(connectFailed("Install Metamask."));
    }
  };
};

export const loadContracts = () => {
  return async (dispatch) => {
    // const { ethereum } = window;
    // if (ethereum) {
      const SmartContractObj = new Web3EthContract(
        abi,
        config.CONTRACT_ADDRESS,
        // { provider: ethereum }
      );
      const AffiliateContractObj = new Web3EthContract(
        affiliateAbi,
        config.AFFILIATE_CONTRACT_ADDRESS,
        // { provider: ethereum }
      );
      dispatch({
        type: "LOAD_CONTRACTS",
        payload: {
          smartContract: SmartContractObj,
          affiliateContract: AffiliateContractObj,
        }
      })

  }
}

export const updateAccount = (account) => {
  return async (dispatch) => {
    dispatch(updateAccountRequest({ account: account }));
    dispatch(fetchData(account));
  }
}

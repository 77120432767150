import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { connect, loadContracts } from "./redux/blockchain/blockchainActions";
import { fetchData, fetchProof, fetchBalanceOf } from "./redux/data/dataActions";
import { isAddress } from "ethers";
import { useAccount, useAccountEffect } from 'wagmi';
import { ConnectButton } from '@rainbow-me/rainbowkit';
import {Decimal} from 'decimal.js';
import Content from './Content.js';
import config from './config/config'

const imageLink = 'https://bafybeia3sr25gyzjo6ycy4edkfh4ww3qrj4ymif4xeum5i2bwgt7rf4tni.ipfs.nftstorage.link/ipfs/bafybeia3sr25gyzjo6ycy4edkfh4ww3qrj4ymif4xeum5i2bwgt7rf4tni/images/'

const getPrice = (quantity, verified) => {
    if(verified) quantity -= 1
    const unit = new Decimal(((parseInt((Date.now()/1000).toFixed()) < 1664596800) ? .0111 : .0333));
    if(quantity >= 5 && quantity < 11) {
        return parseFloat(unit.mul(quantity).mul(.9).toFixed(6));
    } else if (quantity >= 11) {
        return parseFloat(unit.mul(quantity).mul(.81).toFixed(6));
    } else {
        return parseFloat(unit.mul(quantity).toFixed(6));
    }
}

// Array(20).fill().map((element, index) => index + 1).map(i => console.log(i, getPrice(i, true)))
// Array(20).fill().map((element, index) => index + 1).map(i => console.log(i, getPrice(i, false)))

function App() {
  const dispatch = useDispatch();
  const blockchain = useSelector((state) => state.blockchain);
  const data = useSelector((state) => state.data);
  const [claimingNft, setClaimingNft] = useState(false);
  const [mintAmount, setMintAmount] = useState(5);
  const [tokenIndicies, setTokenIndicies] = useState([]);
  const [price, setPrice] = useState(getPrice(mintAmount, data.verified));
  const [feedback, setFeedback] = useState(`Mint your cigawrette packs.`);
  const [successful, setSuccessful] = useState(false)

  const { address } = useAccount()

  const claimNFTs = (address) => {
    let cost = getPrice(mintAmount, (data.verified && data.balance < 1))*Math.pow(10,18);
    // let gasLimit = config.GAS_LIMIT;
    let totalCostWei = String(cost);
    // let totalGasLimit = String(gasLimit);
    console.log("Cost: ", totalCostWei);
    // console.log("Gas limit: ", totalGasLimit);
    setFeedback(`Minting your ${config.NFT_NAME}...`);
    setClaimingNft(true);
    ((data.verified && data.balance < 1) ?
      (blockchain.smartContract.methods
        .freeMint(mintAmount, data.proof)
        .send({
          to: config.CONTRACT_ADDRESS,
          from: address,
          value: totalCostWei,
        })
        .once("error", (err) => {
          console.log(err);
          setFeedback("Sorry, something went wrong please try again later.");
          setClaimingNft(false);
        })
        .then((receipt) => {
          console.log(receipt);
          const transfer = receipt.events.Transfer
          setTokenIndicies(Array.isArray(transfer) ?
            transfer.map(i => i.returnValues.tokenId) :
            [transfer.returnValues.tokenId])
          setFeedback(
            `WOW, the ${config.NFT_NAME} is yours! go visit Opensea.io to view it.`
          );
          setClaimingNft(false);
          setSuccessful(true);
          dispatch(fetchData(address));
        })
        .catch((err) => {
          console.log(err);
          setFeedback("Mint your cigawrette packs.");
          setClaimingNft(false);
        })) :
      (blockchain.smartContract.methods
        .mint(mintAmount)
        .send({
          to: config.CONTRACT_ADDRESS,
          from: address,
          value: totalCostWei,
        })
        .once("error", (err) => {
          console.log(err);
          setFeedback("Sorry, something went wrong please try again later.");
          setClaimingNft(false);
        })
        .then((receipt) => {
          console.log(receipt);
          setFeedback(
            `WOW, the ${config.NFT_NAME} is yours! go visit Opensea.io to view it.`
          );
          setClaimingNft(false);
          setSuccessful(true);
          dispatch(fetchData(address));
        }))
        .catch((err) => {
          console.log(err);
          setFeedback("Mint your cigawrette packs.");
          setClaimingNft(false);
        })
    )
  };

  const decrementMintAmount = () => {
    let newMintAmount = mintAmount - 1;
    if (newMintAmount < 1) {
      newMintAmount = 1;
    }
    setMintAmount(newMintAmount);
  };

  const incrementMintAmount = () => {
    let newMintAmount = mintAmount + 1;
    if (newMintAmount > 10) {
      newMintAmount = 10;
    }
    setMintAmount(newMintAmount);
  };

  const getData = (address) => {
    dispatch(fetchData(address));
  };

  const getProof = (address) => {
    dispatch(fetchProof(address));
  };

  const getBalanceOf = (address) => {
    dispatch(fetchBalanceOf(address));
  }


  useEffect(() => {
    dispatch(loadContracts())
  }, []);

  useAccountEffect({
    onConnect(data) {
      if(isAddress(data.address)) {
        getData(data.address);
        getProof(data.address);
        getBalanceOf(data.address);
      }
    }
  })

  return (
    <>
      <h1 className="title">cigawrette packs</h1>
      {
        successful ? (
          <div className="packs">
            <script async src="https://platform.twitter.com/widgets.js" charSet="utf-8"></script>
            {
              tokenIndicies.map(i => (
                <div className="pack-container" key={i}>
                  <img className="animate__animated animate__zoomInDown pack" alt={`pack-${i}`} src={imageLink + i + '.jpg'}/>
                  <div className="icons">
                    <a target="_blank" rel="noopener noreferrer" href={`https://twitter.com/share?text=The proof of smoke era is upon us:%20\n&url=${config.MARKETPLACE_LINK + i}&hashtags=cigawrettes`} className="twitter-share-button"><img src="./Twitter-logo.svg" alt="tweet" className="icon"/></a>
                    <a target="_blank" rel="noopener noreferrer" href={config.MARKETPLACE_LINK + i}><img src="./OpenSea_icon.svg" alt="opensea" className="icon"/></a>
                  </div>
                </div>
              ))
            }
            <button className="return" onClick={() => setSuccessful(false)}>Mint More Packs!</button>
          </div>
        ) : (
          <div className="app items-center">
            <img id="mint" src="cigawrettes.jpg" alt="cigawrettes vending machine" className="vending"/>
            {
              (!isAddress(address)) ? (
                <div className="wallet">
                  Connect to Ethereum {config.NETWORK.NAME}
                  <div className="flex [&>div>button]:items-center pt-6">
                    <ConnectButton/>
                  </div>
                  {blockchain.errorMsg !== "" ? (
                    <div>
                        {blockchain.errorMsg}
                    </div>
                  ) : null}
                </div>
              ) : (
                <div className="wallet">
                  {feedback}
                  <div className="amount">
                    <button className="bg-white background-animate drop-shadow-[2px_2px_4px_rgba(0,0,0,0.15)] bg-gradient-to-br from-red-200/30 via-red-300/30 to-yellow-200/30 hover:from-red-200/40 hover:via-red-300/40 hover:to-yellow-200/40 rounded-lg m-2 py-2 px-4" onClick={() => decrementMintAmount()}>-</button>
                    <button className="bg-white background-animate drop-shadow-[2px_2px_4px_rgba(0,0,0,0.15)] bg-gradient-to-br from-red-200/30 via-red-300/30 to-yellow-200/30 hover:from-red-200/40 hover:via-red-300/40 hover:to-yellow-200/40 rounded-lg m-2 py-2 px-4"
                      disabled={(claimingNft ? 1 : 0)}
                      onClick={(e) => {
                        e.preventDefault();
                        claimNFTs(address);
                        getData(adress);
                      }}
                    >
                      {claimingNft ? "BUSY" : `BUY  ${mintAmount + (mintAmount > 1 ? ' Packs' : ' Pack')}`}
                    </button>
                    <button className="bg-white background-animate drop-shadow-[2px_2px_4px_rgba(0,0,0,0.15)] bg-gradient-to-br from-red-200/30 via-red-300/30 to-yellow-200/30 hover:from-red-200/40 hover:via-red-300/40 hover:to-yellow-200/40 rounded-lg m-2 py-2 px-4" onClick={() => incrementMintAmount()}>+</button>
                  </div>
                  <div className="pricing">
                    <div className="debug">
                      {
                        // Object.keys(data).map(i => <span key={i}>{`${i}: ${data[i]}`}</span>)
                      }
                    </div>
                      <span className="price">{`${getPrice(mintAmount, (data.verified && data.balance < 1))} ETH`}</span>
                      {
                        `@ ${(getPrice(mintAmount, (data.verified && data.balance < 1))/mintAmount).toFixed(6) } ETH per Pack`
                      }
                      <hr className="leading-5 border-1 border-black mt-2"/>
                    {
                      [1,5,10].map(i => (
                        <div key={i}>
                          <br/>
                          <span>{`${i} Pack${(i > 1 ? 's =' : ' =')} ${(getPrice(i, (data.verified && data.balance < 1))/i).toFixed(6) } ETH per Pack`}</span>
                        </div>))
                    }
                  </div>
                </div>
              )
            }
          <Content/>
          {
            // <a href={config.SCAN_LINK} target="_blank" rel="noopener noreferrer">
            //   <img src="etherscan-logo-circle.svg" alt="etherscan" className="etherscan"/>
            // </a>
          }
        </div>
      )
    }
    <img src="bottom_pile.jpg" alt="bottom_pile" width="100%"/>
    </>
  )
}

export default App

import { useState } from 'react'
import { Check, ChevronsUpDown } from "lucide-react"
import { Button } from "./components/ui/button"
import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandList,
  CommandInput,
  CommandItem,
} from "./components/ui/command"
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "./components/ui/popover"
import { cn } from './lib/utils'

const brands = [
  {
    value: "next.js",
    label: "Next.js",
  },
  {
    value: "sveltekit",
    label: "SvelteKit",
  },
  {
    value: "nuxt.js",
    label: "Nuxt.js",
  },
  {
    value: "remix",
    label: "Remix",
  },
  {
    value: "astro",
    label: "Astro",
  },
]

const Search = ({brandSet, brand, setBrand}) => {
  const [open, setOpen] = useState(false)
  const [value, _setValue] = useState(brand)

  const brands = [...brandSet].map(brand => ({value: brand, label: brand}))

  const setValue = (value) => {
    setBrand(value)
    _setValue(value)
  }

  return (
    <div className="flex flex-col items-center">
      <Popover open={open} onOpenChange={setOpen}>
        <PopoverTrigger asChild>
          <Button
            variant="outline"
            role="combobox"
            aria-expanded={open}
            className="w-[200px] justify-between bg-white bg-gradient-to-b from-blue-100/50 via-blue-100/50 to-blue-300/50"
          >
            {value
              ? brands.find((brand) => brand.value === value)?.label
              : "Select brand..."}
            <ChevronsUpDown className="ml-2 h-4 w-4 shrink-0 opacity-50" />
          </Button>
        </PopoverTrigger>
        <PopoverContent className="w-[200px] p-0">
          <Command>
            <CommandInput placeholder="Search brand..." />
            <CommandEmpty>No brand found.</CommandEmpty>
            <CommandList>
              <CommandGroup>
                {brands.map((brand) => (
                  <CommandItem
                    key={brand.value}
                    value={brand.value}
                    onSelect={(currentValue) => {
                      setValue(currentValue === value ? "" : currentValue)
                      setOpen(false)
                    }}
                  >
                  <Check
                    className={cn(
                      "mr-2 h-4 w-4",
                      value === brand.value ? "opacity-100" : "opacity-0"
                    )}
                  />
                    {brand.label}
                  </CommandItem>
                ))}
              </CommandGroup>
            </CommandList>
          </Command>
        </PopoverContent>
      </Popover>
    {brand !== ""
      ? <button
          className="m-2 text-sm border px-2 rounded-lg hover:bg-slate-200"
          onClick={() => setValue("")}>
          Clear Selection
        </button>
      : <div className="m-5"></div>}
    </div>
  )
}

export default Search

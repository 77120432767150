// log
import store from "../store";
import { readContract } from '@wagmi/core'
import abi from '../../config/abi'
import { config as wagmiConfig } from '../../wagmi.config';
import config from '../../config/config'

const fetchDataRequest = () => {
  return {
    type: "CHECK_DATA_REQUEST",
  };
};

const fetchDataSuccess = (payload) => {
  return {
    type: "CHECK_DATA_SUCCESS",
    payload: payload,
  };
};

const fetchDataFailed = (payload) => {
  return {
    type: "CHECK_DATA_FAILED",
    payload: payload,
  };
};

const fetchProofSuccess = (payload) => {
  return {
    type: "FETCH_PROOF_SUCCESS",
    payload: payload,
  };
}

const fetchBalanceSuccess = (payload) => {
  return {
    type: "FETCH_BALANCE_SUCCESS",
    payload: payload,
  };
}

const fetchPoolSuccess = (payload) => {
  return {
    type: "FETCH_POOL_SUCCESS",
    payload: payload,
  };
}

const fetchAffiliateTransfersSuccess = (payload) => {
  return {
    type: "FETCH_AFFILIATE_TRANSFERS_SUCCESS",
    payload: payload,
  };
}



export const fetchData = (account) => {
  return async (dispatch) => {
    dispatch(fetchDataRequest());
    try {
      let totalSupply = await store
        .getState()
        .blockchain.smartContract.methods.totalSupply()
        .call();
      // let cost = await store
      //   .getState()
      //   .blockchain.smartContract.methods.cost()
      //   .call();

      dispatch(
        fetchDataSuccess({
          totalSupply,
          // cost,
        })
      );
    } catch (err) {
      console.log(err);
      dispatch(fetchDataFailed("Could not load data from contract."));
    }
  };
};

export const fetchProof = (account) => {
  return async (dispatch) => {
   fetch(`api/get_proof.js?address=${account}`)
    .then((response) => response.json())
    .then((data) => dispatch(fetchProofSuccess(data)));
  }
}

export const fetchBalanceOf = (account) => {
  return async (dispatch) => {
    try {
      const balance = await readContract(wagmiConfig, {
        abi,
        address: config.CONTRACT_ADDRESS,
        args: [account],
        functionName: 'balanceOf',
      })

      dispatch(
        fetchBalanceSuccess({
          balance: parseInt(balance)
        })
      );
    } catch (err) {
      console.log(err);
      //dispatch(fetchDataFailed("Could not load data from contract."));
    }

  }
}

export const fetchPool = (account) => {
  return async (dispatch) => {
   fetch(`api/get_pool.js`)
    .then((response) => response.json())
    .then((data) => dispatch(fetchPoolSuccess({pool: data})));
  }
}

export const fetchAffiliateTransfers = (account) => {
  return async (dispatch) => {
   fetch(`api/get_affiliate_transfers.js?address=${account}`)
    .then((response) => response.json())
    .then((data) => dispatch(fetchAffiliateTransfersSuccess(data)));
  }
}

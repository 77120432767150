import { http, createConfig } from 'wagmi'
import { mainnet } from 'wagmi/chains'
import { injected, metaMask, safe, walletConnect } from 'wagmi/connectors'
import { connectorsForWallets } from '@rainbow-me/rainbowkit';
import {
  injectedWallet,
  metaMaskWallet,
  safeWallet,
  trustWallet,
  rainbowWallet,
  walletConnectWallet,
  omniWallet,
  zerionWallet
} from '@rainbow-me/rainbowkit/wallets';

const connectors =  connectorsForWallets([
    {
      groupName: 'Recommended',
      wallets: [metaMaskWallet, rainbowWallet, walletConnectWallet],
    },
    {
      groupName: 'More Options',
      wallets: [injectedWallet, safeWallet, trustWallet, omniWallet, zerionWallet]
    }
  ],
  {
    appName: 'cigawrettes',
    projectId: 'fb5c08c63fbce1de0035cb478e979d5f'
  }
)

export const config = createConfig({
  appName: 'cigawrettes',
  projectId: 'fb5c08c63fbce1de0035cb478e979d5f',
  chains: [mainnet],
  transports: {
     [mainnet.id]: http(),
   },
  connectors
})

const initialState = {
  loading: false,
  totalSupply: 0,
  cost: 0,
  error: false,
  proof: [],
  balance: 0,
  transfers: [],
  pool: [],
  verified: false,
  errorMsg: "",
};

const dataReducer = (state = initialState, action) => {
  switch (action.type) {
    case "CHECK_DATA_REQUEST":
      return {
        ...state,
        loading: true,
        error: false,
        errorMsg: "",
      };
    case "CHECK_DATA_SUCCESS":
      return {
        ...state,
        loading: false,
        totalSupply: action.payload.totalSupply,
        // cost: action.payload.cost,
        error: false,
        errorMsg: "",
      };
    case "CHECK_DATA_FAILED":
      return {
        ...initialState,
        loading: false,
        error: true,
        errorMsg: action.payload,
      };
    case "FETCH_PROOF_SUCCESS":
      return {
        ...state,
        proof: action.payload.proof,
        verified: action.payload.verified,
      };
    case "FETCH_BALANCE_SUCCESS":
      return {
        ...state,
        balance: action.payload.balance,
      };
    case "FETCH_AFFILIATE_TRANSFERS_SUCCESS":
      return {
        ...state,
        transfers: action.payload.transfers,
      };
    default:
      return state;
  }
};

export default dataReducer;
